import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col } from "react-bootstrap"

const SecondPage = () => {
    const data = useStaticQuery(graphql`
        query {
            strapiPricing {
                description
            }
        }
    `)

    return (
        <Layout>
            <SEO title="Kosten" />

            <Container className={`py-0 py-md-5 my-5`}>
                <ReactMarkdown source={data.strapiPricing.description} escapeHtml={false} />
            </Container>
        </Layout>
    )
}

export default SecondPage
